import React, { useEffect, useState } from "react";
import {
    FormControl,
    Typography,
    Select,
    MenuItem,
    LinearProgress,
    Fade,
    Checkbox,
    ListItemText,
    List,
    ListItem,
    ListItemIcon,
    Button,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Snackbar,
} from "@material-ui/core/";

import { Moment } from "moment-timezone";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import { useVideExportRequest, useVideoExportData } from "src/hooks/useVideoExportData";
import { useAuth } from "src/contexts/AuthStateContext";
import { VideoExportPost } from "src/entities/VideoExportData";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { Site } from "../../../entities";
import "./_video-export.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Skeleton } from "@material-ui/lab";

type Props = {
    site: Site;
} & RouteComponentProps;

export const VideoExport = (props: Props) => {
    const { site } = props;
    const { t } = useTranslation();
    const { exportVideoData, isLoading } = useVideoExportData({
        siteId: site.id,
        partnerId: site.partner.id,
    });
    const { logout } = useAuth();
    const {
        postVideoExportRequest,
        videoExportPostIsLoading,
        videoExportPostHasError,
        videoExportPostSuccess,
    } = useVideExportRequest({
        logout,
    });

    const { cameras, reasons } = exportVideoData || { cameras: [], reasons: [] };
    const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [selectedStartTime, setSelectedStartTime] = useState<Moment | null>(null);
    const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(null);
    const [note, setNote] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageSeverity, setMessageSeverity] = useState<Color | undefined>("info");

    useEffect(() => {
        if (videoExportPostHasError) {
            setMessage(t("Entities.VideoExport.ErrorExportRequest"));
            setMessageSeverity("error");
            setShowMessage(true);
        }
        if (videoExportPostSuccess) {
            setMessage(t("Entities.VideoExport.Success"));
            setMessageSeverity("success");
            setShowMessage(true);
        }
    }, [videoExportPostHasError, videoExportPostSuccess]);

    const onCameraSelected = (cameraId: string) => () => {
        if (selectedCameras.includes(cameraId)) {
            setSelectedCameras(selectedCameras.filter((id) => id !== cameraId));
        } else {
            setSelectedCameras([...selectedCameras, cameraId]);
        }
    };

    const showWarningMessage = (key: string) => {
        setMessage(t(key));
        setMessageSeverity("warning");
        setShowMessage(true);
    };

    const onSubmit = async () => {
        if (!selectedDate || !selectedStartTime || !selectedEndTime) {
            showWarningMessage("Entities.VideoExport.DateAndTimeRequired");
            return;
        }
        if (selectedStartTime.isAfter(selectedEndTime)) {
            showWarningMessage("Entities.VideoExport.ErrorStartTimeAfterEndTime");
            return;
        }
        if (!selectedCameras.length) {
            showWarningMessage("Entities.VideoExport.ErrorNoCameraSelected");
            return;
        }
        if (!selectedReasons.length) {
            showWarningMessage("Entities.VideoExport.ErrorNoReasonSelected");
            return;
        }

        selectedDate.hour(0);
        selectedDate.minute(0);
        selectedDate.second(0);
        selectedDate.millisecond(0);

        const startTime = selectedDate.clone();
        startTime.add(selectedStartTime.hours(), "hours");
        startTime.add(selectedStartTime.minutes(), "minutes");
        startTime.add(selectedStartTime.seconds(), "seconds");

        const endTime = selectedDate.clone();
        endTime.add(selectedEndTime.hours(), "hours");
        endTime.add(selectedEndTime.minutes(), "minutes");
        endTime.add(selectedEndTime.seconds(), "seconds");

        const request: VideoExportPost = {
            siteId: site.id,
            cameraIds: selectedCameras,
            reasons: selectedReasons,
            startTimeUtc: startTime.utc(),
            stopTimeUtc: endTime.utc(),
            details: note,
        };

        await postVideoExportRequest(request);
    };

    return (
        <div className="video-export">
            <Fade in={isLoading || videoExportPostIsLoading} timeout={{ enter: 700, exit: 400 }}>
                <LinearProgress />
            </Fade>
            <Accordion defaultExpanded classes={{ root: "video-export__panel" }}>
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}>
                    <Typography variant="h6">{t("Entities.VideoExport.SelectCameras")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List style={{ width: "100%" }}>
                        {!isLoading ? (
                            cameras.map((camera) => (
                                <ListItem
                                    key={camera.id}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={onCameraSelected(camera.id)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            checked={selectedCameras.includes(camera.id)}
                                        ></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText primary={camera.name} />
                                </ListItem>
                            ))
                        ) : (
                            <>
                                <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                                <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                                <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                            </>
                        )}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded classes={{ root: "video-export__panel" }}>
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}>
                    <Typography variant="h6">{t("Entities.VideoExport.SelectDateAndTime")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={"sv-se"}>
                        <Grid container direction="column">
                            <Box mb={2} mt={0}>
                                <DatePicker
                                    value={selectedDate}
                                    label={t("Entities.VideoExport.SelectDate")}
                                    margin="normal"
                                    DialogProps={{ fullWidth: true }}
                                    onChange={(date: MaterialUiPickersDate) => {
                                        setSelectedDate(date);
                                    }}
                                />
                            </Box>
                            <Grid container direction="row">
                                <Box m={2} marginLeft={0}>
                                    <TimePicker
                                        value={selectedStartTime}
                                        label={t("Entities.VideoExport.StartTime")}
                                        DialogProps={{ fullWidth: true }}
                                        ampm={false}
                                        onChange={(time: MaterialUiPickersDate) => {
                                            setSelectedStartTime(time);
                                        }}
                                    />
                                </Box>
                                <Box m={2}>
                                    <TimePicker
                                        value={selectedEndTime}
                                        label={t("Entities.VideoExport.EndTime")}
                                        DialogProps={{ fullWidth: true }}
                                        ampm={false}
                                        onChange={(date: MaterialUiPickersDate) => {
                                            setSelectedEndTime(date);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded classes={{ root: "video-export__panel" }}>
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}>
                    <Typography variant="h6">{t("Entities.VideoExport.SelectReasons")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Select
                        multiple
                        value={selectedReasons}
                        onChange={(e) => {
                            setSelectedReasons(e.target.value as string[]);
                        }}
                        renderValue={(selected) => (selected as string[]).join(", ")}
                        className="video-export__reason-select"
                    >
                        {reasons.map((reason) => (
                            <MenuItem key={reason} value={reason}>
                                <Checkbox checked={selectedReasons.includes(reason)} />
                                <ListItemText primary={reason} />
                            </MenuItem>
                        ))}
                    </Select>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded classes={{ root: "video-export__panel" }}>
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}>
                    <Typography variant="h6">{t("Entities.VideoExport.OtherNotes")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        value={note}
                        onChange={(e) => {
                            setNote(e.target.value);
                        }}
                        placeholder={t("Entities.VideoExport.OtherNotesPlaceholder")}
                        variant="outlined"
                        style={{ width: 400 }}
                    />
                </AccordionDetails>
            </Accordion>
            <Box my={3}>
                <FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSubmit();
                        }}
                        disabled={isLoading || videoExportPostIsLoading}
                    >
                        {t("Entities.VideoExport.SubmitButton")}
                    </Button>
                </FormControl>
            </Box>
            <Box my={2} mb={12}>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={() => {
                        setShowMessage(false);
                        setMessage("");
                    }}
                    open={showMessage}
                >
                    <MuiAlert elevation={6} variant="filled" severity={messageSeverity}>
                        {t(message)}
                    </MuiAlert>
                </Snackbar>
            </Box>
        </div>
    );
};
