import moment from "moment-timezone";
import { useMemo } from "react";
import { ConfigurationGroup, Site, SiteDetails, VideoStream } from "src/entities";
import { apiUrl } from "src/settings";
import { useData } from "./useData";

const useSite = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/slim`;
    const { data: site, isLoading: siteIsLoading, hasError: siteHasError } = useData<Site>(url);
    return { site, siteIsLoading, siteHasError };
};

const useVideoStreams = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/videostreams`;
    const { data: videoStreamList, isLoading: videoStreamsIsLoading, hasError: videoStreamsHasError } = useData<
        VideoStream[]
    >(url);

    const videoStreams = videoStreamList?.map((videoStream) => ({
        alarmZones: videoStream.alarmZones,
        id: videoStream.id,
        name: videoStream.name,
        dtLastImageUpdate: moment.utc(videoStream.dtLastImageUpdate).toDate(),
        periodicImageUploadStatus: videoStream.periodicImageUploadStatus,
        images: videoStream.images,
        latestImageUpdateStatus: videoStream.latestImageUpdateStatus,
        siteEquipmentName: videoStream.siteEquipmentName,
        index: videoStream.index,
        dtCreated: moment.utc(videoStream.dtCreated).toDate(),
        dtLastState: moment.utc(videoStream.dtLastState).toDate(),
    }));

    return { videoStreams, videoStreamsIsLoading, videoStreamsHasError };
};

const useConfigurations = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/configurations/withSchedules`;
    const { data: configurations, isLoading: configurationsIsLoading, hasError: configurationsHasError } = useData<
        ConfigurationGroup[]
    >(url);
    return { configurations, configurationsIsLoading, configurationsHasError };
};

export const useSiteDetails = (props: { siteId: string; siteDetailsEnabled: boolean }) => {
    const { siteId } = props;
    const { site, siteIsLoading, siteHasError } = useSite(siteId);
    const { videoStreams, videoStreamsIsLoading, videoStreamsHasError } = useVideoStreams(siteId);
    const { configurations, configurationsIsLoading, configurationsHasError } = useConfigurations(siteId);
    const siteDetails = useMemo(() => {
        if (!props.siteDetailsEnabled) {
            return { site, configurationGroups: [], siteId, videoStreams: [] } as SiteDetails;
        }

        if (videoStreams && configurations && site) {
            return {
                site,
                configurationGroups: configurations,
                siteId,
                videoStreams,
            } as SiteDetails;
        }
        return undefined;
    }, [videoStreams, configurations, site]);

    if (!props.siteDetailsEnabled) {
        return {
            siteDetails,
            siteDetailsIsLoading: siteIsLoading,
            siteDetailsHasError: siteHasError,
        };
    }

    return {
        siteDetails,
        siteDetailsIsLoading: videoStreamsIsLoading || configurationsIsLoading || siteIsLoading,
        siteDetailsHasError: videoStreamsHasError || configurationsHasError || siteHasError,
    };
};
